import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import Nav from '../components/nav'

const ErrorPage = () => {
	return (
    <div>
      <Nav />
      <Header />
      <div className="w-full pt-12 pb-12 border-b-2 border-grey">
        <div className="flex justify-around items-center text-center">
          <div className="w-10/12 sm:w-8/12 lg:w-8/12 xl:w-6/12">
            <div className="font-verdana font-bold text-black text-6xl sm:text-6xl lg:text-6xl leading-none mb-6">
              <h1>Page not found</h1>
            </div>
            <div className="max-w-none font-verdana prose prose-md lg:prose-lg leading-tight">
              <p>We couldn’t find what you were looking for.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
	)
}

export default ErrorPage;
