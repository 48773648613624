import React from 'react'
import { Link } from 'gatsby'

const Header = () => {
  return (
    <>
      <div className="w-full relative">
        <div className="absolute -inset-0">
          <img src="/images/uploads/a.jpg" alt="Christchurch Roof Painting" className="object-cover object-center w-full h-full block" /> 
        </div>
        <div className="absolute -inset-0 bg-gradient-to-l from-white">
          &nbsp;
        </div>
        <div className="w-10/12 mx-auto max-w-screen-xl relative">
          <div className="flex justify-between items-center gap-6">
            <div className="w-6/12 sm:w-3/12">
              <Link to="/">
                <img src="/images/logo.jpg" className="w-72 py-3 px-6 bg-white" alt="Christchurch Roof Painting" />
              </Link>
            </div>
            <div className="w-6/12 sm:w-9/12 text-right">
              <div className="hidden lg:flex lg:justify-end lg:gap-6 items-center font-verdana text-xl">
                <div className="font-bold">
                  Call <a href="tel:0800266871" title="0800 266 871" rel="noopener nofollow">(0800) 266 871</a>
                </div>
                <Link to="/contact/" className="py-3 px-6 bg-red font-bold text-white">Request Quote <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header;